/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
export default {
  props: {
    // 选中tabbar
    selected: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentSelected: this.selected,
      //tabbar格式
      tabBars: [{
        name: "/zyInfo",
        badge: "",
        text: "主要信息",
        icon: "description"
      }, {
        name: "/hkh",
        badge: "",
        text: "汇款后",
        icon: "after-sale"
      }, {
        name: "/bszz",
        badge: "",
        text: "标书制作后",
        icon: "completed"
      }, {
        name: "/tbjg",
        badge: "",
        text: "投标结果",
        icon: "sign"
      }]
    };
  },
  methods: {},
  // 初始化页面选中状态
  created() {
    /*
      由于 vant 标签栏路由模式，无法自动加载页面，所以这里需要初始化
      console.log(this.$route); //打印当前路由属性
      console.log(this.tabBars);
      console.log(this.$props.selected);
      console.log(this.currentSelected);
      this.$router.push("/Module1");
      */
    if (this.$route.path === "/zbInfoHom" && this.tabBars.length) {
      this.$router.push(this.tabBars[this.currentSelected].name);
    }
  },
  mounted() {}
};