/* this file is transformed by vux-loader */
/* eslint-disable */
import ZBInfoNav from "./ZBInfoNav";
import { sessionStorage } from "../../../../assets/myjs/glovar";
export default {
  components: {
    ZBInfoNav
  },
  data() {
    return {
      selected: 0
    };
  },
  methods: {
    onChange(index) {
      console.log("外部-切换到tabbar：" + index);
      this.tabBars[index].isShowRedDot = this.tabBars[index].isShowRedDot ? false : false;
    }
  },
  created() {}
};